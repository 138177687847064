<template>
  <div class="management bj">
    <div class="title d-flex a-center j-sb">
      <div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>

        <el-button type="primary" @click="addclick">新增</el-button>
      </div>
      <div class="d-flex">
        <div class="form-item" style="margin-right: 10px">
          <el-input v-model="from1.username" placeholder="输入账号" clearable>
          </el-input>
        </div>

        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="username" align="center" label="账号">
          <template slot-scope="scope" v-if="scope.row.user">
            {{ $empty.empty(scope.row.user.username) }}
          </template>
        </el-table-column>
        <el-table-column prop="nickname" align="center" label="账号昵称">
          <template slot-scope="scope" v-if="scope.row.user">
            {{ $empty.empty(scope.row.user.nickname) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="模块名称">
          <template slot-scope="scope" v-if="scope.row.business_type">
            {{ $empty.empty(scope.row.business_type.name) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="业务名称">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.name) }}
          </template>
        </el-table-column>

        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == 1">启用</el-tag>
            <el-tag type="warning" v-else>禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status != 1"
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
              >启用</el-button
            >
            <el-button
              v-else
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
              >禁用</el-button
            >
            <!-- <el-button type="text" @click="selectda(scope.$index, scope.row)"
              >修改</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!--
新增中心账号 -->
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="40%"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          label-width="110px"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item label="业务模块" prop="business_type_code">
            <div class="inputs">
              <el-select
                v-model="ruleForm.business_type_code"
                clearable
                placeholder="选择业务模块"
                @change="seletlist"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item
            v-if="work == 'delivery'"
            label="业务中心"
            prop="store_id"
          >
            <div class="inputs">
              <el-select
                v-model="ruleForm.store_id"
                clearable
                placeholder="选择业务中心"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="业务名称" prop="name">
            <div class="inputs">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入业务名称"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="业务昵称" prop="nickname">
            <div class="inputs">
              <el-input
                v-model="ruleForm.nickname"
                placeholder="请输入业务名称"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="账号" prop="username">
            <div class="inputs">
              <el-input
                v-model="ruleForm.username"
                placeholder="请输入账号"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <div class="inputs">
              <el-input
                type="password"
                v-model="ruleForm.password"
                placeholder="请输入密码"
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="tianku">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "management",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        page: 1,
        pageSize: 10,
        username: "",
      },
      from1: {
        page: 1,
        pageSize: 10,
        username: "",
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      ruleForm: {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        name: "",
        nickname: "",
        username: "",
        password: "",
      },
      title: "",
      centerDialogVisible: false,
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        name: [{ required: true, message: "请输入业务名称", trigger: "blur" }],
        business_type_code: [
          { required: true, message: "请选择业务模块", trigger: "change" },
        ],
        store_id: [
          { required: true, message: "请选择业务中心", trigger: "change" },
        ],
      },
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      options1: [],
      work: "",
      list: [],
      list1: [],
    };
  },
  created() {
    this.hqlist();
    // this.seletlist();
  },
  methods: {
    // 搜索
    onHandleSearch() {
      this.from.page = 1;
      this.currentPage = 1;
      this.from1.page = 1;
      // this.from1 = this.from;
      this.from = this.from1;
      console.log(this.from1, this.from, "==");
      this.hqlist();
    },
    addclick() {
      console.log("新增");

      this.ruleForm = {
        business_type_code: "", //集配：logistics 自提点：delivery
        store_id: "",
        name: "",
        username: "",
        password: "",
      };

      this.centerDialogVisible = true;
      this.title = "新增中心账号";
    },
    tianku() {
      console.log(this.ruleForm);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.work == "logistics") {
            delete this.ruleForm.store_id;
          }
          this.$api.login.addbusiness(this.ruleForm).then((res) => {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 800);
          });
        }
      });
    },
    Refresh() {
      this.from = {
        page: 1,
        pageSize: 10,
        username: "",
      };
      this.from1 = {
        page: 1,
        pageSize: 10,
        username: "",
      };
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    handleEdit(index, row) {
      this.$confirm(
        row.status === 1
          ? "确定要禁用该中心账号吗？"
          : "确定要启用该中心账户吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.login
            .businessstatus({
              id: row.id,
              status: row.status === 1 ? 0 : 1,
            })
            .then((res) => {
              console.log(res, "禁用或者启用");
              this.$message({
                type: "success",
                message: row.status === 1 ? "禁用成功" : "启用成功",
              });
              this.hqlist();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: row.status === 1 ? "取消禁用" : "取消启用",
          });
        });
    },
    selectda(index, row) {
      this.title = "修改中心账号";
      this.ruleForm.business_type_code = row.business_type_code;
      this.seletlist(this.ruleForm.business_type_code);
      for (let key in this.ruleForm) {
        this.ruleForm[key] = row[key];
      }
      //修改参数显示不全，问题
      // if (row.business_type_code == "logistics") {
      //   this.list.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // } else {
      //   this.list1.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // }
      console.log(this.ruleForm, this.list, this.list);
      this.centerDialogVisible = true;
      // this.shownode = false;
      // this.$api.shipper.shipperinfo({ id: row.id }).then((res) => {
      //   console.log(res, "获取数据");
      //   this.info = res.data;
      //
      // });
    },
    hqlist() {
      this.loading = true;
      this.$api.login.businesslist(this.from1).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    seletlist(val) {
      this.work = val;
      if (val == "delivery") {
        this.$api.login.unusedall().then((res) => {
          console.log(res, "获取所有可配置的自提点");
          this.options1 = res.data;
        });
      } else {
        this.$api.login.logisticsunusedall().then((res) => {
          console.log(res, "获取所有可配置的城市仓");
          this.options1 = res.data;
        });
      }
    },
  },
};
</script>
<style lang="scss">
.management {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;
    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }
    .imgs {
      display: flex;
      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;
        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    .disply {
      display: flex;
      margin-bottom: 22px;
    }
    .mr {
      margin-right: 31px;
    }
    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }
      .el-input {
        width: 302px;
      }
    }
  }
}
.colors {
  color: #fa884c;
}
.times {
  margin-right: 8px;
}
.el-picker-panel {
  left: 1245px !important;
}
.inputs {
  width: 300px;
}
</style>
